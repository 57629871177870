<!--
* @Description:学习中心->调查问卷
-->
<template>
  <div class="trainingWrap container">
    <header>
      <el-row class="hedaer-Bg" type="flex">
        <el-col :span="12">
          <div class="grid-content header-left-content">
            <div class="logo-wrap">
              <img src="../../assets/image/homeImg/logo.png" />
            </div>
            <div class="app-title" @click="$router.push('/home')">
              在线培训平台
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content header-right-content">
            <div @click="pageJumpLogin" v-if="userName == ''">登录</div>
            <div v-else class="userName-wrap">
              <el-dropdown placement="bottom">
                <span class="el-dropdown-link">
                  学员{{ userName }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="$router.push('/accountSafe')">账号安全</el-dropdown-item>
                  <el-dropdown-item @click.native="$router.push('/login')">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="nav_side">
        <nav>
          <router-link active-class="active" to="/home">首页</router-link>
          <router-link active-class="active" to="/trainingClass">安全培训</router-link>
          <router-link active-class="active" to="/trainingClass2">职业卫生</router-link>
          <router-link active-class="active" to="/studyCenter"
            >学习中心</router-link
          >
          <router-link active-class="active" to="/examCenter"
            >考试中心</router-link
          >
<!--          <router-link active-class="active" to="/newcomerPost"-->
<!--            >新人驿站</router-link-->
<!--          >-->
<!--          <router-link active-class="active" to="/liveRoom"-->
<!--            >直播大厅</router-link-->
<!--          >-->
<!--          <router-link active-class="active" to="/boutiqueCourse"-->
<!--            >精品课程</router-link-->
<!--          >-->
          <router-link active-class="active" to="/trainDynamic"
            >培训动态</router-link
          >
          <router-link active-class="active" to="/helpCenter"
            >帮助中心</router-link
          >
        </nav>
      </div>
    </header>
    <div class="main-content">
      <div class='topTip'>亲爱的学员朋友们:为了更好的开展工作，我们制定了本次培训效果评估调查问卷，请大家积极进行反馈。很满意 (5分) ;满意 (4分) :一般 (3分) ;不满意 (2分) ;很不满意 (1分)，请您选择所评内容的等级栏。</div>
      <div class="questionnaireTitle">{{ surveyTitle }}</div>
      <div v-for="(item_moduleList, index_moduleList) in questionnaireInfoList" :key="index_moduleList">
        <div class='moduleTitle'>{{UNUM[index_moduleList+1]}}、{{item_moduleList.moduleTitle}}</div>
        <div v-for="(item, index) in item_moduleList.sectionList" :key="index">
          <div class="sectionTitle">
            <img src="../../assets/dcwj_splic.png" alt="" srcset="" />
            <div>
              <div style="white-space: pre-line">{{item.sectionTitle}}</div>
              <div>{{item.sectionIntro}}</div>
            </div>
          </div>
          <div class="tableContent" v-show="item.chooseQuestionList.length>0">
            <div class="t_header">
              <div class="th_title">题目/选项</div>
              <div>5</div>
              <div>4</div>
              <div>3</div>
              <div>2</div>
              <div>1</div>
            </div>
            <div
              class="t_tr"
              v-for="(choose_item, choose_index) in item.chooseQuestionList"
              :key="choose_index"
            >
              <div class="tr_title">{{ choose_item.questionTitle }}</div>
              <div><el-radio v-model="choose_item.answer" :label="5"></el-radio></div>
              <div><el-radio v-model="choose_item.answer" :label="4"></el-radio></div>
              <div><el-radio v-model="choose_item.answer" :label="3"></el-radio></div>
              <div><el-radio v-model="choose_item.answer" :label="2"></el-radio></div>
              <div><el-radio v-model="choose_item.answer" :label="1"></el-radio></div>
            </div>
          </div>
          <div  class="tableContent discussContent" v-for="(discuss_item,discuss_index) in item.discussQestionList" :key='discuss_index'>
            <div class="discussContent_title ">{{ discuss_item.questionTitle }}</div>
            <el-input
              type="textarea"
              :autosize="{ minRows:6 , maxRows: 10}"
              placeholder="请输入..."
              v-model="discuss_item.answerContent">
            </el-input>
          </div>
        </div>
      </div>
      <div class='submitBtn'>
        <el-button v-if='!submitDisabled' type="primary" :disabled="!isWriteComplate" @click="surveySubmitFun">完成</el-button>
      </div>
    </div>
    <footer-com></footer-com>
  </div>
</template>

<script>
import footerCom from '@/components/footer.vue'
const UNUM = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
export default {
  name: '',
  components: {
    footerCom,
  },
  props: {},
  watch: {
    questionnaireInfoList: {
      handler: function (val) {
        console.log(val)
        let flage = true
        for (let index_moduleList = 0; index_moduleList < val.length; index_moduleList++) {
          const sectionList = val[index_moduleList].sectionList
          for (let index = 0; index < sectionList.length; index++) {
            let {chooseQuestionList, discussQestionList} = sectionList[index]
            if (chooseQuestionList.length) {
              for (let choose_index = 0; choose_index < chooseQuestionList.length; choose_index++) {
                if (chooseQuestionList[choose_index].answer == 0) {
                  flage = false
                }
              }
            }
            if (discussQestionList.length) {
              for (let discuss_index = 0; discuss_index < discussQestionList.length; discuss_index++) {
                if (discussQestionList[discuss_index].answerContent.trim() == '') {
                  flage = false
                }
              }
            }
          }
        }

        this.isWriteComplate = flage
      },
      deep: true,
    }
  },
  data () {
    return {
      UNUM: UNUM,
      userName: '',
      id: '',
      surveyInfoId: '',
      surveyTitle: '',
      radio: 1,
      submitDisabled: true,
      questionnaireInfoList: [],
      isWriteComplate: false,
    }
  },
  methods: {
    pageJumpLogin () {
      this.$router.push('/login')
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    // 获取单个调查问卷的详情
    getLoadStudentSurvey () {
      this.$nextTick(() => {
        for (let index = 0; index < document.getElementsByClassName('el-radio__label').length; index++) {
          const element = document.getElementsByClassName('el-radio__label')[index]
          element.style.visibility = 'hidden'
        }
      })
      this.$axios
        .get(this.$apiUrl.loadStudentSurvey, {
          params: {
            id: this.id,
          },
        })
        .then((response) => {
          let res = response.data
          console.log(res)
          if (res.success) {
            console.log(res.data)
            this.questionnaireInfoList = res.data.moduleList
            this.submitDisabled = res.data.status == 'FINISHED'
          }
        })
    },
    // 获取local中用户详细信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      console.log(userinfo)
      if (userinfo) {
        let { stuName } = userinfo
        this.userName = stuName
      }
    },
    // 调查问卷提交
    surveySubmitFun () {
      console.log(this.questionnaireInfoList)
      //   let data = { answerList: [{ answer: 2, id: 2 }], id: 2 }
      let data = {id: this.id, answerList: []}
      this.questionnaireInfoList.forEach(item_moduleList => {
        item_moduleList.sectionList.forEach((item) => {
          if (item.discussQestionList.length) {
            item.discussQestionList.forEach(discuss_item => {
              data.answerList.push({answerContent: discuss_item['answerContent'], id: discuss_item['id']})
            })
          }
          if (item.chooseQuestionList.length) {
            item.chooseQuestionList.forEach(choose_item => {
              data.answerList.push({answer: choose_item['answer'], id: choose_item['id']})
            })
          }
        })
      })
      this.$axios.put(this.$apiUrl.surveySubmit, data).then((response) => {
        let res = response.data
        if (res.success) {
          console.log('提交成功')
          this.$message({
            type: 'success',
            message: '提交成功'
          })
          setTimeout(() => {
            this.$router.push('/diaochawenjuan')
          }, 0)
        }
      })
    },
  },
  mounted () {
    console.log(this.$route.query)
    let {id, surveyInfoId, surveyTitle} = this.$route.query
    this.id = id
    this.surveyInfoId = surveyInfoId
    this.surveyTitle = surveyTitle
    this.getLoadStudentSurvey()
    this.getUserInfo()
  },
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
  min-height: 100vh;
  background-color: #fbfbff;

  header {
    background: url("../../assets/image/homeImg/headerBg.png");
    background-size: 100% 100%;
    height: 146px;
  }

  .hedaer-Bg {
    /* background-color:#6a8dea; */
    background-size: 100% 100%;
    height: 42px;
    width: 1240px;
    margin: 0 auto;
    line-height: 56px;
    /* padding: 0 100px; */

    .header-left-content {
      font-size: 22px;
      color: white;
      font-weight: 800;
      display: flex;
      justify-content: start;

      .app-title {
        cursor: pointer;
      }

      .logo-wrap {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 42px;
        }
      }
    }

    .header-right-content {
      font-size: 18px;
      color: white;
      height: 42px;
      display: flex;
      justify-content: end;
      align-items: center;

      .userName-wrap {
        height: 34px;
        line-height: 34px;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      ::v-deep .el-dropdown {
        font-size: 18px;
        color: #4774df;
        background-color: #fff;
        height: 34px;
        line-height: 34px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        padding: 0px 10px;
      }
    }
  }

  .nav_side {
    width: 1240px;
    margin: 0 auto;
    height: 61px;
    line-height: 61px;

    nav {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 22px;
      font-weight: bold;
    }

    nav a {
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 61px;
    }

    nav a:hover {
      opacity: 1;
    }

    .active {
      font-size: 26px;
      font-weight: bold;
      color: #ffffff;
      opacity: 1;
      border-bottom: 3px solid #ffffff;
    }
  }
}
.main-content {
  width: 1240px;
  padding-bottom: 188px;
  margin: 0 auto 0;
  padding: 30px;
  padding-bottom: 188px;
  background-color: transparent;
  .topTip{
    font-size: 18px;
    padding-bottom: 20px;
    border-bottom: 1px dashed #cecece;
    margin-bottom: 20px;
  }
  .questionnaireTitle {
    font-size: 22px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #1c4ecc;
  }
  .moduleTitle{
    padding-top:40px;
    font-size: 24px;
    font-weight: 800;
  }
  .sectionTitle {
    font-size: 20px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    display: flex;
    padding: 20px 0px 20px;
    img {
      width: 14px;
      height: 32px;
      margin-right: 20px;
    }
  }
  .discussContent{
    .discussContent_title{
      font-size: 20px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      padding: 30px 0px 10px;
    }
  }
  .tableContent {
    padding: 0px 40px;
    width: 1180px;
    .t_header {
      display: flex;
      font-size: 24px;
      background: #e9e9e9;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      height: 91px;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;
      > div {
        width: 144px;
        display: flex;
        //justify-content: center;
        align-items: center;
        text-indent: 63px;
        border-right: 1px solid;
      }
      .th_title {
        flex: 1;
        border-right: 1px solid #707070;
        border-left: 1px solid #707070;
      }
    }
    .t_tr {
      display: flex;
      font-size: 24px;
      background: #fff;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      height: 91px;
      border-bottom: 1px solid #707070;
      > div {
        width: 144px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid;
      }
      .th_title {
        flex: 1;
        border-right: 1px solid #707070;
      }
      .tr_title {
        flex: 1;
        padding: 0px 10px;
        border-right: 1px solid #707070;
        border-left: 1px solid #707070;
        font-size: 16px;
      }
    }

  }
}

.submitBtn {
  padding-top: 20px;
  padding-right: 100px;
  margin-bottom: 100px;

  ::v-deep button {
    float: right;
    width: 103px;
    height: 44px;
    background: #5EA9F4;
    border-radius: 6px 6px 6px 6px;
  }
  ::v-deep .is-disabled{
    background-color: #C6C6C6
  }
}

::v-deep .el-divider {
  margin-top: 20px;
  margin-bottom: 0px;
}

::v-deep .el-radio__label {
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

::v-deep .el-radio__inner {
  width: 32px;
  height: 32px;
}
::v-deep .el-radio__label{
  display: none;
}
::v-deep .el-radio__inner::after{
  background-color: #3388ffa8;
  width: 24px;
  height: 24px;
}
::v-deep .el-textarea__inner{
  font-size: 18px
}
</style>
